<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Prijava</h4>
                </div>

                <div>
                  <vs-input
                    name="email"
                    autocomplete="username"
                    type="email"
                    icon-no-border
                    icon="icon icon-user"
                    icon-pack="feather"
                    label="Uporabniško ime"
                    v-model="email"
                    class="w-full"/>

                  <vs-input
                    type="password"
                    autocomplete="current-password"
                    name="password"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label="Geslo"
                    v-model="password"
                    @keyup.enter="login()"
                    class="w-full mt-6"/>

<!--                  <div class="flex flex-wrap justify-between my-5">-->
<!--                    <router-link to="">Forgot Password?</router-link>-->
<!--                  </div>-->
                  <vs-button class="float-right mb-10 mt-10" @click="login">Prijava</vs-button>


                  <div class="w-full">
                  <vs-chip transparent color="success" class="my-10">
                    Aplikacija in njene vsebine so POSLOVNA SKRIVNOST Zavoda Sopotniki.
                  </vs-chip>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    data() {
      return {
        email: '',
        password: ''
      }
    },

    mounted() {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("selectedUnit");
    },

    methods: {
      login() {
        const _this = this;

        _this.$vs.loading();

        Vue.prototype.$http.post('login', {email: _this.email, password: _this.password})
          .then((res) => {
            if(!res.data.error) {

              localStorage.setItem("userInfo", JSON.stringify(res.data.data));

              _this.$store.dispatch('updateUserInfo', res.data.data).then(() => {
                  _this.$vs.loading.close();
                  _this.$router.push('/').catch(() => {})
              });
            } else {
              console.log("error", res.data);
              _this.$vs.loading.close();
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }


  }
</script>

<style lang="scss">
  #page-login {

  }
</style>
